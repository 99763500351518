<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import QRCode from "qrcode";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      block: false,
      networkcardlist: [],
      wifilist: [],
      lanport: "",
      laniptype: 0,
      wlanport: "",
      dnsinfo: [],
      wlandata: {
        ssid: "",
        password: "",
      },
      wlanloading: true,
      dnsloading: false,
      systemtypeloading: true,
      systemtypes: [
        {
          value: "0",
          label: this.$t("setting.system.default"),
        },
        {
          value: "1",
          label: this.$t("setting.system.counter"),
        },
        {
          value: "2",
          label: this.$t("setting.system.card"),
        },
        {
          value: "3",
          label: this.$t("setting.system.cocktail")
        }
      ],
      systemtype: 0,
      systeminfo: [],
      serialstatus: 0,
      supportserial: false,
      tcpport: "",
      tcploading: true,
      printerid: "",
      printerloading: true,
      otp_status: false,
      tfasecret: "",
    };
  },
  mounted() {
    var that = this;
    if ("serial" in navigator) {
      that.supportserial = true
      if (localStorage.getItem("serial")) {
        that.serialstatus = localStorage.getItem("serial");
      }
    }

    that.title = that.$t("menuitems.setting.system");
    that.items = [
      {
        text: that.$t("menuitems.setting.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.setting.system"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (that.userinfo.topuid >= 1) {
      that.block = true;
    }
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.getsysteminfo();
    that.gettcpinfo();
    that.getotpstatus();
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtypeloading = false;
          that.systeminfo = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    changesystemmodel() {
      var that = this;
      that.systemtypeloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savesystemmodel",
            type: that.systeminfo.model,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtypeloading = false;
          that.$message({
            message: that.$t("global.success"),
            type: "success",
          });
          that.getsysteminfo();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    gettcpinfo() {
      var that = this;
      that.tcploading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "gettcpport",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.tcploading = false;
          that.tcpport = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async enable_serial() {
      var that = this;
      if (that.serialstatus == 0) {
        that.serialstatus = 1;
        localStorage.setItem("serial", 1);
        that
          .$confirm(that.$t("setting.serial.tip"), that.$t("update.status.tip"), {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          })
          .then(() => {
            that.openserial();
          })
          .catch(() => { });
      } else {
        that.serialstatus = 0;
        localStorage.setItem("serial", 0);
        setTimeout(() => {
          that.$message({
            message: that.$t("setting.serial.disable"),
            type: "success",
          });
          location.reload();
        }, 1000);
      }
    },
    async openserial() {
      var that = this;

      const port = await navigator.serial.requestPort();
      await port.open({ baudRate: 9600 });
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable.getReader();
      while (true) {
        const { value, done } = await reader.read();
        let strdata;
        if (done) {
          // 允许稍后关闭串口。
          reader.releaseLock();
          break;
        }
        // value 是一个 Uint8Array
        if (value.includes("=")) {
          let bitdata = value.split("=");
          let cardhex = bitdata[1].replace(/[\r\n]/g, "");
          cardhex = cardhex.replace(/\n/g, "");
          cardhex = cardhex.replace(/\r/g, "");

          let route = that.$route.path;
          if (route.includes("user/detail")) {
            that.bindcard(cardhex);
          } else {
            that.searchcarduid(cardhex);
          }
        }
      }
    },
    searchcarduid(hex) {
      var that = this;
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.$message(that.$t("global.loading.reading"));
      that.$axios
        .post(
          that.apiuri,
          {
            action: "finduser",
            hex: hex,
          },
          {
            headers: { Openid: userinfo.openid },
          }
        )
        .then(function (response) {
          let res = response.data;
          if (res.status == 200) {
            that.$message({
              message: that.$t("global.ws.finduser"),
              type: "success",
            });
            that.$router.push({
              path: "/user/detail",
              query: { id: res.data.id },
            });
          } else if (res.status == 202) {
            that.cardcheckout(hex);
          } else {
            let route = that.$route.path;
            if (route.includes("user/add")) {
            } else {
              that.$router.push({
                path: "/user/add",
                query: { id: hex },
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    cardcheckout(hex) {
      var that = this;
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.$message.error(that.$t("global.loading.reading"));
      that
        .$confirm(
          that.$t("device.order.checkouttip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "checkout",
                hex: hex,
              },
              {
                headers: { Openid: userinfo.openid },
              }
            )
            .then(function (response) {
              that.$router.push({ path: "/device/orders" });
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => { });
    },
    bindcard(hex) {
      var that = this;
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.$message.error(that.$t("global.loading.reading"));
      that
        .$confirm(
          that.$t("global.ws.userbindcard"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "bindnewcard",
                hex: hex,
                uid: that.$route.query,
              },
              {
                headers: { Openid: userinfo.openid },
              }
            )
            .then(function (response) {
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.ws.bindsuccess"),
                  type: "success",
                });
                that.$router.push({
                  path: "/user/list",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => { });
    },
    getotpstatus() {
      var that = this;
      that.$axios.post(that.apiuri, { action: "getotpstatus" }).then(function (response) {
        if (response.data.status == 200) {
          that.otp_status = true;
        }
      }).catch(function (error) { });
    },
    async generateSecret() {
      try {
        const response = await this.$axios.post(this.apiuri, {
          action: "makeotptoken"
        }, {
          headers: { Openid: this.userinfo.openid },
        });

        if (response.data.status === 200) {
          this.tfasecret = response.data.secret;

          const canvas = this.$refs.canvas;
          const options = { width: 256, height: 256 };
          const dataUrl = await QRCode.toDataURL(response.data.uri, options);

          canvas.width = options.width;
          canvas.height = options.height;
          const ctx = canvas.getContext("2d");
          const img = new Image();
          img.onload = () => {
            ctx.drawImage(img, 0, 0, options.width, options.height);
            canvas.toBlob((blob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.qrCode = reader.result;
              };
              reader.readAsDataURL(blob);
            });
          };
          img.src = dataUrl;

          this.$alert(
            `<img width="100%" src="${img.src}">`,
            this.$t("setting.system.otpbind_qrcode"),
            {
              confirmButtonText: this.$t("global.button.next"),
              dangerouslyUseHTMLString: true,
              callback: (action) => {
                if (action === "confirm") {
                  // 弹出输入框用于确认2FA验证码
                  this.$prompt(
                    this.$t("setting.system.otpbind_input"),
                    this.$t("setting.system.otpbind_confirm"),
                    {
                      confirmButtonText: this.$t("global.button.ok"),
                      cancelButtonText: this.$t("global.button.cancel"),
                      inputPattern: /^[0-9]{6}$/,
                      inputErrorMessage: this.$t("setting.system.otpbind_input_error"),
                    }
                  ).then(({ value }) => {
                    this.$axios.post(this.apiuri, {
                      action: "checkotptoken",
                      code: value,
                      secret: this.tfasecret,
                    },
                      {
                        headers: { Openid: this.userinfo.openid },
                      }
                    ).then((response) => {
                      this.getotpstatus()
                      if (response.data.status === 200) {
                        this.$message({
                          message: this.$t("setting.system.otpbind_success"),
                          type: "success",
                        });
                        this.otp_status = true;
                      } else {
                        this.$message({
                          message: this.$t("setting.system.otpbind_failed"),
                          type: "error",
                        });
                      }
                    }).catch((error) => {
                      console.error("绑定密钥失败:", error);
                      this.$message({
                        message: this.$t("setting.system.otpbind_error"),
                        type: "error",
                      });
                    });
                  }).catch(() => { });
                }
              },
            }
          );
        }
      } catch (error) {
        // 适当处理错误，例如显示错误信息
        console.error("生成密钥失败:", error);
        this.$alert(
          this.$t("setting.system.otpbind_error"),
          this.$t("global.error"),
          { type: "error" }
        );
      }
    },
    reboottcp(){
      var that = this;
      that.tcploading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "reboottcp",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.tcploading = false;
          that.$message({
            message: that.$t("global.success"),
            type: "success",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  },
  computed: {
    lancardlist() {
      var datas = this.networkcardlist;
      datas = datas.filter((item, idx) => {
        console.log(idx);
      });

      return datas;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!block">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="systemtypeloading">
            <h5 class="card-title">{{ $t("setting.system.softtype") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.softtype')">
                <el-select v-model="systeminfo.model" :placeholder="$t('global.text.select')">
                  <el-option v-for="item in systemtypes" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="changesystemmodel">{{
                  $t("setting.system.network.save")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="tcploading">
            <h5 class="card-title">{{ $t("setting.system.tcpserver") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.tcpport')">
                <el-input type="int" min="4001" max="39999" v-model="tcpport" disabled
                  :placeholder="$t('setting.system.tcpport')"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button @click="reboottcp">{{
                  $t("setting.system.restart")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="supportserial">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              {{ $t("menuitems.clouds.nfcreader.text") }}
            </h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.action')">
                <el-button type="primary" @click="enable_serial" v-if="serialstatus == 0">{{
                  $t("setting.system.enableserial") }}</el-button>
                <el-button type="primary" @click="enable_serial" v-if="serialstatus == 1">{{
                  $t("setting.system.disableserial") }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              {{ $t("setting.system.otp") }}
            </h5>
            <div v-if="!otp_status">
              <el-button type="primary" @click="generateSecret">{{ $t("setting.system.otpbind") }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-result icon="error" :title="$t('global.error')" :subTitle="$t('global.text.nopermission')">
        <template slot="extra">
          <el-button type="primary" size="medium">返回</el-button>
        </template>
      </el-result>
    </div>
    <canvas style="display: none" ref="canvas"></canvas>
  </Layout>
</template>